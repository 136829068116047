import React from "react";

import { FlatList, TouchableOpacity } from "react-native";

import DiscountPercentBadge, {
  Props as BadgeProps,
} from "cuanto/web-app/components/DiscountPercentBadge";
import ProductPicture from "cuanto/web-app/features/Product/ProductPicture.web";

import { hasInventory } from "../../../services/Product";
import Button from "../components/Button";
import Icon from "../components/Icon";
import PriceInfo from "../components/PriceInfo";
import { Merchant, PaymentPlanType, Price, Product } from "../types";

// this is equivalent to h-80, which is equivalent to 20rem or 320px
// use to optimize flatlist loading
const LIST_ITEM_HEIGHT_IN_PX = 320;

type Props = {
  products: Product[];
  merchant: Merchant;
  priceGroups: Record<string, Price[]>;
  productGroups: Record<string, Product[]>;
  storeClosed: boolean;
  numColumns: number;
  handleSelect: Function;
  handleAddToCart: (product: Product) => void;
};

export default function ProductsList(props: Props) {
  const {
    merchant,
    products,
    priceGroups,
    productGroups,
    storeClosed,
    numColumns,
    handleSelect,
    handleAddToCart,
  } = props;
  return (
    <div className="flex flex-wrap">
      <FlatList
        data={products}
        numColumns={numColumns}
        initialNumToRender={numColumns}
        key={numColumns}
        columnWrapperStyle={{ justifyContent: "space-evenly" }}
        keyExtractor={(item) => `${item.id}`}
        renderItem={({ item: product, index }) => {
          const isOutOfStock = !hasInventory(product, productGroups);
          const prices = priceGroups[product.uuid] || [];

          return (
            <div
              className="flex flex-col justify-center items-center"
              style={{ height: LIST_ITEM_HEIGHT_IN_PX }}
            >
              <TouchableOpacity
                onPress={() => {
                  handleSelect(product);
                }}
              >
                <CatalogueListItem
                  product={product}
                  prices={prices}
                  merchant={merchant}
                  isOutOfStock={isOutOfStock}
                />
              </TouchableOpacity>
              <CatalogueListItemButton
                product={product}
                prices={prices}
                isOutOfStock={isOutOfStock}
                storeClosed={storeClosed}
                onClick={() => handleAddToCart(product)}
              />
            </div>
          );
        }}
        getItemLayout={(data, index) => ({
          length: LIST_ITEM_HEIGHT_IN_PX,
          offset: LIST_ITEM_HEIGHT_IN_PX * index,
          index,
        })}
      />
    </div>
  );
}

type ItemProps = {
  product: Product;
  prices: Price[];
  merchant: Merchant;
  isOutOfStock: boolean;
};
const IMAGE_SIZE = 300;

function CatalogueListItem({
  product,
  prices,
  isOutOfStock,
  merchant,
}: ItemProps) {
  const { name, price, price_not_discounted, currency, images = [] } = product;

  const pictureStyles = `w-44 h-44 rounded-lg overflow-hidden relative ${
    isOutOfStock && "opacity-30"
  }`;

  const productNameStyles = `text-sm font-medium line-clamp-2 overflow-ellipsis m-0 h-10 ${
    isOutOfStock ? "text-gray-400" : "text-gray-500"
  }`;

  const priceStyles = `text-sm font-bold ${
    isOutOfStock ? "text-gray-400" : "text-gray-900"
  }`;

  return (
    <div className={"flex flex-col justify-center"}>
      <div className={pictureStyles}>
        <ProductPicture name={name} image={images[0]} imageSize={IMAGE_SIZE} />
        <DiscountedPercentBanner
          price={price}
          priceNotDiscounted={price_not_discounted}
          fontSize={8}
        />
      </div>
      {/* add h-16 to name and price to account for 2 line names */}
      <div className={"flex flex-col py-2 w-44 justify-start"}>
        <p
          data-testid={`cataloguelist-item-name`}
          className={productNameStyles}
        >
          {name}
        </p>
        <PriceInfo
          prices={prices}
          currency={merchant.currency_code}
          fallbackPrice={price}
        />
      </div>
    </div>
  );
}

function DiscountedPercentBanner(props: BadgeProps) {
  if (!props.priceNotDiscounted) return null;

  return (
    <div className="absolute flex w-full bottom-0 justify-between items-center bg-black bg-opacity-80">
      <span
        className="text-white-100 m-1 font-semibold"
        style={{ fontSize: props.fontSize }}
      >
        ¡EN OFERTA!
      </span>

      <DiscountPercentBadge {...props} />
    </div>
  );
}

type ItemButtonProps = {
  product: Product;
  prices: Price[];
  storeClosed?: boolean;
  isOutOfStock?: boolean;
  onClick: () => void;
};
function CatalogueListItemButton({
  product,
  prices,
  storeClosed,
  isOutOfStock,
  onClick,
}: ItemButtonProps) {
  const disabled = isOutOfStock || storeClosed;

  const hasSingleOneOffPrice =
    prices.length === 1 &&
    prices[0].payment_plan_type === PaymentPlanType.ONE_OFF;

  function buttonText() {
    if (storeClosed) {
      return "Tienda Cerrada";
    } else if (isOutOfStock) {
      return "Agotado";
    } else if (hasSingleOneOffPrice) {
      return "Agregar al carrito";
    } else {
      return "Comprar";
    }
  }

  return (
    <Button
      text={buttonText()}
      small
      icon={<Icon.Cart />}
      buttonType="secondary"
      onClick={onClick}
      disabled={disabled}
    />
  );
}
