import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { ConnectedProps, connect } from "react-redux";

import * as cartActions from "cuanto/features/Cart/actions";
import useAPI from "cuanto/package/hooks/APIContext";
import { CartProduct } from "cuanto/types/Product";
import * as catalogueActions from "cuanto/web-app/actions";

import {
  CatalogueData,
  PaymentPlanType,
  Price,
  PriceData,
  PriceGroups,
  Product,
} from "../types";

type BaseContext = {
  priceGroups: PriceGroups;
  addToCart: (product: Product) => AddToCartResponse;
  addToCartWithPrice: (product: Product, price: Price) => AddToCartResponse;
  isRecurrence: (priceUuid: string | null | undefined) => boolean;
};

type LoadedContext = { catalogueData: CatalogueData; isLoading: false };
type LoadingContext = { catalogueData: null; isLoading: true };

type TContext = BaseContext & (LoadedContext | LoadingContext);

type AddToCartRedirect = "cart" | "prices" | "catalogue" | null;
type AddToCartResponse = { redirectTo: AddToCartRedirect };

const Context = createContext<TContext | null>(null);

export default function useCatalogue() {
  const ctx = useContext(Context);
  if (!ctx) throw new Error("Missing CatalogueProvider");
  return ctx;
}

type ProviderProps = PropsWithChildren<ReduxProps & { username?: string }>;
function CatalogueProvider({ children, username, ...props }: ProviderProps) {
  const api = useAPI();

  const { fetchCatalogue } = props;
  const [catalogueData, setCatalogueData] = useState<CatalogueData | null>(
    null
  );

  const [hasPricesLoaded, setHasPricesLoaded] = useState(false);
  const [priceGroups, setPriceGroups] = useState<PriceGroups>({});
  const allPrices = Object.values(priceGroups).flat();

  useEffect(() => {
    async function getCatalogue() {
      fetchCatalogue(username);
      const { data } = await api.get<CatalogueData>(`/${username}/products`);
      setCatalogueData(data);
    }

    async function getPrices() {
      const { data } = await api.get<PriceData>(
        `/ecommerce/${username}/prices`
      );
      setHasPricesLoaded(true);
      const priceGroups = buildPriceGroups(data);
      setPriceGroups(priceGroups);
    }

    if (username) {
      getCatalogue();
      getPrices();
    }
  }, [api, fetchCatalogue, username]);

  // This is called from catalogue and catalogue link screen.
  function addToCart(product: Product): AddToCartResponse {
    const productPrices =
      (product.product_group_uuid
        ? priceGroups[product.product_group_uuid]
        : priceGroups[product.uuid]) || [];

    // Clear so the user selects a new one. Potentially not needed.
    props.clearSelectedPrice();

    switch (productPrices.length) {
      case 0:
        // Basic product, no need to redirect the user can keep shopping.
        props.addToCart({ product, prices: allPrices });
        return { redirectTo: null };
      case 1:
        // We can auto-select the price.
        addToCartWithPrice(product, productPrices[0]);
        return { redirectTo: null };
      default:
        // Take the user to price selection, can't add to cart if there's a price to select.
        return { redirectTo: "prices" };
    }
  }

  // This is only called from price selection screen.
  function addToCartWithPrice(
    product: Product,
    price: Price
  ): AddToCartResponse {
    const productWithPrice = buildProductWithPrice(product, price);
    if (price.payment_plan_type === PaymentPlanType.ONE_OFF) {
      props.addToCart({ product: productWithPrice, prices: allPrices });
      return { redirectTo: "catalogue" };
    } else {
      // Cart can only have one item with recurring price.
      props.resetCart();
      console.log("selecting Price", { price });
      props.selectPrice(price);
      props.addToCart({ product: productWithPrice, prices: allPrices });
      return { redirectTo: "cart" };
    }
  }

  // Returns true if the price is a recurring payment.
  function isRecurrence(price_uuid: string | null | undefined): boolean {
    if (!price_uuid) return false;
    const price = Object.values(priceGroups)
      .flat()
      .find((price) => price.uuid === price_uuid);
    return price?.payment_plan_type === PaymentPlanType.RECURRING;
  }

  const isLoading = !catalogueData || !hasPricesLoaded;

  return (
    <Context.Provider
      value={{
        priceGroups,
        addToCart,
        addToCartWithPrice,
        isRecurrence,
        ...(isLoading
          ? { catalogueData: null, isLoading }
          : { catalogueData, isLoading }),
      }}
    >
      {children}
    </Context.Provider>
  );
}

const mapDispatchToProps = {
  fetchCatalogue: catalogueActions.async.fetchCatalogue,
  addToCart: cartActions.addToCart,
  resetCart: cartActions.resetCart,
  createCart: cartActions.async.createCart,
  selectPrice: cartActions.selectPrice,
  clearSelectedPrice: cartActions.clearSelectedPrice,
};

const connector = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

const ConnectedCatalogueProvider = connector(CatalogueProvider);

export { ConnectedCatalogueProvider as CatalogueProvider };

function buildPriceGroups(data: PriceData) {
  return data.reduce<PriceGroups>((acc, price) => {
    if (!acc[price.product_uuid]) {
      acc[price.product_uuid] = [];
    }
    acc[price.product_uuid].push(price);
    return acc;
  }, {});
}

function buildProductWithPrice(product: Product, price: Price): CartProduct {
  return {
    ...product,
    price: price.amount,
    price_uuid: price.uuid,
    quantity: 1,
    hasIcon: false,
  };
}
