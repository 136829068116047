import React, { useEffect, useReducer, useState } from "react";

import { cloneDeep, get, isEqual, toPairs } from "lodash";

import Select from "../components/Select";
import { hasInventory } from "../services/ProductInventory";
import { Product } from "../types";

type VariantsMap = Map<string, Set<string>>;
type VariantSelectionState = { variant_options: Product["variant_options"] };

type PickerProps = {
  variants: VariantsMap | null;
  products: Product[];
  onChange: (key: string, value: string) => void;
  showVariantError?: boolean;
  variantSelection: VariantSelectionState;
};

const initialState: VariantSelectionState = { variant_options: {} };

/** must be used alongside `useVariantPicker` */
export default function ProductVariantPicker({
  variantSelection,
  variants,
  products,
  showVariantError = false,
  onChange,
}: PickerProps) {
  const [lastFilter, setLastFilter] = useState("");

  if (!variants || variants.size < 1) {
    return null;
  }

  const outOfStockLabel = "(AGOTADO)";
  const variantsKeys = Array.from(variants.keys());
  const filtersKeys = Object.keys(get(variantSelection, "variant_options", []));

  // We render `option`s without `value`s to keep them showing the `outOfStockLabel`
  // but the filters should be applied from their original values
  const originalFilters = cloneDeep(initialState);
  filtersKeys.forEach((key) => {
    originalFilters.variant_options[key] = variantSelection.variant_options[
      key
    ].replace(` ${outOfStockLabel}`, "");
  });

  const filteredVariants = products.filter((p) =>
    isEqual(p.variant_options, originalFilters.variant_options)
  );

  return (
    <div className="flex flex-col">
      {variantsKeys.map((key) => {
        const attributes = variants.get(key);
        const filteredVariant = filteredVariants[0];

        const isLastSelected = lastFilter === key;
        const isVariantOutOfStock =
          filteredVariants.length === 1 && !hasInventory(filteredVariant);

        return (
          <Select
            key={key}
            defaultValue={key}
            error={
              isLastSelected && isVariantOutOfStock
                ? "Este modelo se encuentra agotado. Por favor, selecciona otro modelo."
                : ""
            }
            onChange={({ target: { value } }) => {
              setLastFilter(key);
              onChange(key, value);
            }}
          >
            <option key={key} disabled hidden>
              {key}
            </option>
            {attributes &&
              Array.from(attributes).map((attribute) => {
                const attributesFilter = {
                  variant_options: {
                    ...variantSelection.variant_options,
                    ...{ [key]: attribute },
                  },
                };

                const productsWithAttributes = products.filter((p) =>
                  isEqual(p.variant_options, attributesFilter.variant_options)
                );

                const productWithAttributes = productsWithAttributes[0];

                const isOutOfStock =
                  productsWithAttributes.length === 1 &&
                  !hasInventory(productWithAttributes);

                return (
                  <option
                    key={attribute}
                    className="text-gray-900"
                    value={attribute}
                  >
                    {attribute} {isOutOfStock && outOfStockLabel}
                  </option>
                );
              })}
          </Select>
        );
      })}
      {showVariantError && filteredVariants.length !== 1 && (
        <div className="mb-2 text-red-600">
          Por favor selecciona una opción para agregar al carrito.
        </div>
      )}
    </div>
  );
}

export function useVariantPicker({
  productGroup,
  productVariants = [],
}: {
  productGroup: Product;
  productVariants: Product[];
}) {
  const [products, setProducts] = useState<Product[]>([]);
  const [variants, setVariants] = useState<VariantsMap | null>(null);

  const [currentVariantSelection, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const variantsMap: VariantsMap = new Map();
    productVariants.forEach((product) => {
      // variant_options: { color: "Red", size: "M" } }
      const pairs = toPairs(product.variant_options);
      // [["color", "Red"], ["size", "M"]]
      pairs.forEach(([k, v]) => {
        // Working under the assumption that keys from data in backend are consistent.
        variantsMap.set(k, (variantsMap.get(k) || new Set()).add(v));
      });
      // Map(2) {"size" => Set(3) {"M", "XL", "L"}, "color" => Set(2) {"Blue", "Red"}}
    });

    setProducts(productVariants);
    setVariants(variantsMap);
  }, [productGroup.uuid, productVariants]);

  const filteredProductVariants = products.filter((p) =>
    isEqual(p.variant_options, currentVariantSelection.variant_options)
  );

  const hasVariants = variants && variants.size > 0;

  return {
    hasVariants,
    variants,
    currentVariantSelection,
    filteredProductVariants,
    dispatch,
  };
}

type Action = { type: "filter"; payload: Product["variant_options"] };
function reducer(state: VariantSelectionState, { type, payload }: Action) {
  switch (type) {
    case "filter":
      return {
        variant_options: { ...state.variant_options, ...payload },
      };

    default:
      return state;
  }
}
