import React, { PropsWithChildren } from "react";

import { amountAsCurrency } from "cuanto/services/currency";

import { detailedPricesPreview, pricesPreview } from "../services/price-v2";
import { Currency, PaymentPlanType, Price } from "../types";

type Props = {
  prices: Price[];
  currency: Currency;
  detailed?: boolean;
  fallbackPrice?: number;
};
export default function PriceInfo({
  prices,
  currency,
  detailed,
  fallbackPrice,
}: Props) {
  if (!prices.length && fallbackPrice) {
    return (
      <PriceText>
        {amountAsCurrency({ amount: fallbackPrice, currency })}
      </PriceText>
    );
  }

  const isSingleOneOff =
    prices.length === 1 &&
    prices[0].payment_plan_type === PaymentPlanType.ONE_OFF;

  if (isSingleOneOff) {
    return <SingleOneOffPrice price={prices[0]} currency={currency} />;
  }

  if (detailed) {
    const { anchor, details } = detailedPricesPreview(prices, currency);
    return (
      <PriceText>
        {anchor && <span className="font-bold text-lg">{`${anchor} `}</span>}
        {details}
      </PriceText>
    );
  }

  const text = pricesPreview(prices, currency);
  return <PriceText>{text}</PriceText>;
}

function SingleOneOffPrice({
  price,
  currency,
}: {
  price: Price;
  currency: Currency;
}) {
  const amt = amountAsCurrency({ amount: price.amount, currency });

  if (
    price.amount_not_discounted &&
    price.amount < price.amount_not_discounted
  ) {
    return (
      <PriceText>
        {`${amt} `}
        <span className="line-through text-gray-500">
          {amountAsCurrency({
            amount: price.amount_not_discounted,
            currency,
          })}
        </span>
      </PriceText>
    );
  }

  return <PriceText>{amt}</PriceText>;
}

function PriceText({ children }: PropsWithChildren<{}>) {
  return (
    <span className="font-semibold text-sm text-gray-900">{children}</span>
  );
}
