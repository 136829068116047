import React, { useState } from "react";

import { mdiCartPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { View, Text } from "react-native";

// TODO - pending to move to 'package'
import { tailwind } from "cuanto/tailwind";
// END TODO

import BottomBox from "../components/BottomBox";
import Button from "../components/Button";
import Header from "../components/Header";
import PriceInfo from "../components/PriceInfo";
import Toast, { useToast } from "../components/Toast";
import { hasInventory } from "../services/ProductInventory";
import { Merchant, Product, PaymentPlanType, Price } from "../types";
import ImagesCarousel from "./ImagesCarousel";
import ProductVariantPicker, { useVariantPicker } from "./ProductVariantPicker";

type Props = {
  merchant: Merchant;
  product: Product;
  prices: Price[];
  productVariants: Product[];
  cartQuantity: number;

  storeClosed: boolean;
  onAskQuestions: () => void;
  onGoToCart: () => void;
  onAddToCart: (product: Product) => void;
  onBackPress: () => void;

  inPreviewMode?: boolean;
};

export default function CatalogueLink({
  merchant,
  product,
  prices,
  productVariants,
  inPreviewMode,
  cartQuantity,
  storeClosed,
  onAskQuestions,
  onAddToCart,
  onGoToCart,
  onBackPress,
}: Props) {
  const [showVariantError, setShowVariantError] = useState(false);

  const { toast, showToast } = useToast("Producto agregado a tu carrito");

  const {
    hasVariants,
    variants,
    currentVariantSelection,
    filteredProductVariants,
    dispatch,
  } = useVariantPicker({ productGroup: product, productVariants });

  const invalidVariant = hasVariants && filteredProductVariants.length !== 1;
  const isOutOfStock = !hasInventory(product, productVariants);

  const hasMultiplePrices = prices.length > 1;

  const isSubscription = prices.some(
    (price) => price.payment_plan_type === PaymentPlanType.RECURRING
  );

  const isInstallment = prices.some(
    (price) => price.payment_plan_type === PaymentPlanType.INSTALLMENT
  );

  const isButtonDisabled = invalidVariant || isOutOfStock || storeClosed;

  function handleAddToCart() {
    if (hasVariants) {
      if (filteredProductVariants.length === 1) {
        showToast();
        onAddToCart(filteredProductVariants[0]);
      } else {
        setShowVariantError(true);
      }
    } else {
      showToast();
      onAddToCart(product);
    }
  }

  function getButtonText() {
    if (storeClosed) return "Cerrado";
    if (invalidVariant) return "Selecciona variantes";
    if (isOutOfStock) return "Agotado";
    if (isSubscription) return "Suscribirme";
    if (isInstallment) return "Inscribirme";
    if (!merchant.has_ecommerce_store || hasMultiplePrices) return "Comprar";

    return "Agregar al carrito";
  }

  const buttonText = getButtonText();

  return (
    // TODO: button covers bototm of page
    <div className="relative" style={{ paddingBottom: 200 }}>
      {merchant.has_ecommerce_store && <Toast text={toast} />}

      <div className="py-1.5">
        <Header
          merchant={merchant}
          cartQuantity={cartQuantity}
          navigateToCart={onGoToCart}
          navigateBack={onBackPress}
        />
      </div>
      <ImagesCarousel images={product.images} imageSize={1200} />

      <div className="flex flex-col p-6">
        <span className="font-semibold text-gray-900 leading-6">
          {product.name}
        </span>

        <PriceInfo
          prices={prices}
          currency={merchant.currency_code}
          detailed
          fallbackPrice={product.price}
        />

        <div className="pt-4">
          <DescriptionAndBenefits
            description={product.description}
            benefits={product.benefits}
          />
        </div>

        <ProductVariantPicker
          variants={variants}
          products={productVariants}
          variantSelection={currentVariantSelection}
          showVariantError={showVariantError}
          onChange={(key, value) => {
            dispatch({ type: "filter", payload: { [key]: value } });
          }}
        />

        {merchant.whatsapp_cellphone && (
          <div className="pt-4 self-center w-40">
            <Button
              text="Preguntas"
              onClick={onAskQuestions}
              buttonType="secondary"
              small
              icon={<QuestionCircle />}
            />
          </div>
        )}
      </div>

      {!inPreviewMode && (
        <BottomBox>
          <div className="grid gap-y-2">
            <Button
              text={buttonText}
              icon={isButtonDisabled ? undefined : <Icon path={mdiCartPlus} />}
              disabled={isButtonDisabled}
              buttonType={cartQuantity ? "secondary" : "primary"}
              onClick={handleAddToCart}
            />
            {cartQuantity ? (
              <Button
                text={`Ver carrito (${cartQuantity})`}
                onClick={onGoToCart}
              />
            ) : null}
          </div>
        </BottomBox>
      )}
    </div>
  );
}

const DescriptionAndBenefits = ({
  description,
  benefits,
}: {
  description: string;
  benefits: string[];
}) => {
  return (
    <View>
      <Text
        style={tailwind("text-gray-400 pb-6 text-sm")}
        testID="product-description"
      >
        {description}
      </Text>
      <View style={tailwind("flex-col pb-3")}>
        {benefits.map((perk, i) => (
          <View key={i} style={tailwind("flex-row pb-3")}>
            <View>
              <div className="text-primary-500 h-5 w-5">
                <StarIcon />
              </div>
            </View>
            <Text style={tailwind("pl-4")}>{perk}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

function QuestionCircle() {
  return (
    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
}

function StarIcon() {
  return (
    <svg viewBox="0 0 20 20" fill="currentColor">
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
  );
}
